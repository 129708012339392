:root {
  --custom-color: #E59F71;
}

 html,body{
    overflow-x: hidden;
}


.navbar {
  position: fixed;
  top: 0;
  height: 100px;
  z-index: 1;
}

.navbar.hidden {
  transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.navbar.active {
  transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.hero-b {
  margin: 100px 0;
}

.text-second {
  color: var(--custom-color);
}

.bg-second {
  background-color: var(--custom-color);
}

/* text slide in hero */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.hero-content .slide-in {
  opacity: 0; /* Oculta los elementos antes de la animación */
  animation: slideIn 1s ease-out forwards;
  animation-fill-mode: forwards; /* Mantiene el estado final de la animación */
}

.hero-content .slide-in:nth-child(1) {
  animation-delay: 0.3s;
}

.hero-content .slide-in:nth-child(2) {
  animation-delay: 0.6s;
}

.hero-content .slide-in:nth-child(3) {
  animation-delay: 0.9s;
}

.hero-content .slide-in:nth-child(4) {
  animation-delay: 1.2s;
}


