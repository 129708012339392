/* text slide in hero */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.app-content .slide-in {
  opacity: 0; /* Oculta los elementos antes de la animación */
  animation: slideIn 1s ease-out forwards;
  animation-fill-mode: forwards; /* Mantiene el estado final de la animación */
}

.hero-content .slide-in:nth-child(1) {
  animation-delay: 0.3s;
}

.hero-content .slide-in:nth-child(2) {
  animation-delay: 0.6s;
}

.hero-content .slide-in:nth-child(3) {
  animation-delay: 0.9s;
}

.hero-content .slide-in:nth-child(4) {
  animation-delay: 1.2s;
}

body {
  font-family: "Poppins", sans-serif;
}